import { FC } from "react";
import { ImgBox, LoginCard, PaperBox, Separator, Title } from "./styles";
import { GithubOAuth, GoogleOAuth } from "../../components/oauth";

interface LoginProps {

}

export const Login: FC<LoginProps> = (props) => {

  const handleCLickGoogleOAuth = async () => {
    try {
      const response = await fetch('http://localhost:4000/auth/google');
      const { token } = await response.json();
      // setToken(token);
      console.log('Google OAuth 認證成功:');
      // 在這裡可以進行其他處理，例如將 token 存儲到本地或進行其他操作
    } catch (error) {
      console.error('Google OAuth 認證失敗:', error);
    }
  }

  return <PaperBox>
    <LoginCard >
      <ImgBox src='/logo_fluxnote.PNG' />
      <Title variant="h2">Log in</Title>
      <Separator />
      <GoogleOAuth onClick={handleCLickGoogleOAuth} />
      <GithubOAuth />
    </LoginCard>
  </PaperBox>
}

export default Login;